import type { IReview } from './ReviewsCarousel';

import antonYudintsevPhoto from '@images/People/anton-yudintsev.png';
import joePietroniPhoto from '@images/People/joe-pietroni.jpg';
import jordiSolsonaPhoto from '@images/People/jordi-solsona.jpg';
import madhuVenkateshPhoto from '@images/People/madhu-venkatesh.jpg';
import paoloGarriPhoto from '@images/People/paolo-garri.jpg';
import robertRencPhoto from '@images/People/robert-renc.jpg';
import vinzenzSinapiusPhoto from '@images/People/vinzenz-sinapius.jpg';
import eslLogo from '@logos/Black/esl.svg';
import gaijinLogo from '@logos/Black/gaijin.svg';
import banijayLogo from '@logos/Colored/banijay.svg';
import factorioLogo from '@logos/Colored/factorio.png';
import rakutenLogo from '@logos/Colored/rakuten.svg';
import sport1Logo from '@logos/Colored/sport1.svg';
import tedXPragueLogo from '@logos/Colored/tedXPragueDark.svg';
import udemyLogo from '@logos/Colored/udemy.svg';

export const reviewRakuten: IReview = {
  logo: rakutenLogo,
  text: [
    'As a media business, maintaining a reliable multi-CDN stack for our live and on-demand video delivery is critical. After considering different alternatives, we chose CDN77 to be added to our CDN providers based on the performance metrics, capacity and quick individual approach.',
    'The onboarding process with CDN77 was a breath of fresh air. Their personalized approach and level of customization were beyond expected. Their team consistently went the extra mile to support us through the transition, both technically and from a cost-cutting perspective.',
  ],
  author: {
    name: 'Jordi Solsona',
    role: 'Playback Manager',
    company: 'Rakuten TV',
    photo: jordiSolsonaPhoto,
  },
};

export const reviewUdemy: IReview = {
  logo: udemyLogo,
  text: [
    'At the heart of Udemy, we always put our clients first. CDN77 has helped us offer the best possible user experience as their global CDN service speeds up the delivery of our video courses library.',
    'It also keeps our entire delivery workflow cost-effective thanks to competitive pricing and robust private caching infrastructure that reduces our egress bandwidth volumes.',
  ],
  author: {
    name: 'Madhu Venkatesh',
    role: 'Head of Learn and Media Engineering',
    company: 'Udemy',
    photo: madhuVenkateshPhoto,
  },
};

export const reviewEsl: IReview = {
  logo: eslLogo,
  text: [
    'Being at the forefront of the esports and video games industry means working with partners that share a similar mindset.',
    'A lean, straight-forward, and performance-driven approach to CDN is both refreshing and clutch. We are happy to be working with CDN77 as one of our trusted CDN providers.',
  ],
  author: {
    name: 'Ben Burns',
    role: 'VP of Technology',
    company: 'ESL Gaming',
  },
};

export const reviewBanijay: IReview = {
  logo: banijayLogo,
  text: [
    'Being a producer of highly successful works, such as Big Brother, Black Mirror and Peaky Blinders, we need to cooperate with reliable partners. CDN77 helps us deliver content through Roku. We treasure their high-quality service and exceptional support.',
  ],
  author: {
    name: 'Joe Pietroni',
    role: 'Chief Technology Officer',
    company: 'Banijay',
    photo: joePietroniPhoto,
  },
};

export const reviewSport1: IReview = {
  logo: sport1Logo,
  text: [
    'CDN77 with the combination of their full Live streaming and VOD services enables a smooth user experience for our customers.',
    'Their services encompass everything from RTMP ingest to secure delivery and "on-the-fly" encoding of our VOD content. We value their efficiency, reliability, and exceptional support.',
  ],
  author: {
    name: 'Paolo Garri',
    role: 'Director of Technology',
    company: 'Sport1',
    photo: paoloGarriPhoto,
  },
};

export const reviewFactorio: IReview = {
  logo: { src: factorioLogo, width: 180, height: 30 },
  text: [
    'Just like Factorio’s ever-growing assembly lines, CDN77 is able to adapt and scale to meet our content distribution needs. They ensure fast and reliable access to our extensive database of community-generated content and updates for our 3.5 million players.',
    'Their skilled and flexible team has exceeded our expectations by customizing their services to align with our unique requirements at no extra cost. With CDN77, our players enjoy a smooth gaming experience while we benefit from exceptional customer service.',
  ],
  author: {
    name: 'Vinzenz Sinapius',
    role: 'Operations developer',
    company: 'Factorio',
    photo: vinzenzSinapiusPhoto,
  },
};

export const reviewGaijin: IReview = {
  logo: gaijinLogo,
  text: [
    'CDN77 is incredibly easy to use and setup. Within minutes our customers were amazed at how quickly they could download and play their games. Also, they have quick and helpful customer service. I recommend CDN77 very much.',
  ],
  author: {
    name: 'Anton Yudintsev',
    role: 'CEO',
    company: 'Gaijin Entertainment',
    photo: antonYudintsevPhoto,
  },
};

export const reviewTedX: IReview = {
  logo: tedXPragueLogo,
  author: {
    name: 'Robert Renč',
    role: 'Media Partnerships Manager',
    company: 'TEDxPrague',
    photo: robertRencPhoto,
  },
  text: [
    'We pushed RTMP to CDN77’ streaming servers and their team took care of the rest. CDN77 managed the conversion to HLS and MPEG-DASH as well as transcoding, providing us with a ready-to-embed player. Superb support. Seamless delivery.',
  ],
};
